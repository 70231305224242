<script>
import sucursalSelector from '@/components/helexium/sucursalSelector.vue';
import BVForm from '@/components/BVForm/BVForm'
import indicadoresSchemas from './indicadoresSchemas'
import Swal from 'sweetalert2'

export default {
    name: 'IndicadoresPESV',
    components: {
        sucursalSelector,
        BVForm
    },
    data() {
        return {
            sucursal: null,
            tiposDeIndicadores: [],
            TSVn: indicadoresSchemas.TSVn,
            SVn: indicadoresSchemas.SVn,
            RSVI: indicadoresSchemas.RSVI,
            GRV: indicadoresSchemas.GRV,
            CM_PESV: indicadoresSchemas.CM_PESV,
            CPlan_PESV: indicadoresSchemas.CPlan_PESV,
            EJLC: indicadoresSchemas.EJLC,
            GVE: indicadoresSchemas.GVE,
            ELVL: indicadoresSchemas.ELVL,
            IDP: indicadoresSchemas.IDP,
            CPMVh: indicadoresSchemas.CPMVh,
            CumPF_PESV: indicadoresSchemas.CumPF_PESV,
            CobPF_PESV: indicadoresSchemas.CobPF_PESV,
            NCAC: indicadoresSchemas.NCAC,
            isInRead: 0,
            logs: []
        }
    },
    async mounted() {
        const response = await this.$store.getters.fetchGet({ path: 'PESVIndicadores/tipos' })
        this.tiposDeIndicadores = await response.json();
    },
    methods: {
        dateBackToJs(date) {
            date = String(Date)
            return new Date(date.replace(' ', 'T'))
        },
        setSucursal(sucursal) {
            this.sucursal = sucursal
            this.getAllVySucursal()
        },
        getAllVySucursal() {
            this.$store.getters.fetchGet({ path: `PESVIndicadores/bySucursal/${this.sucursal.value}` })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al obtener los indicadores')
                })
                .then(result => {
                    let ultimosPorFecha = {}

                    result.forEach(indicador => {
                        const key = indicador.pesvIndicadoresTipo.variable
                        if (ultimosPorFecha[key] == null) {
                            ultimosPorFecha[key] = indicador
                            return
                        }
                        
                        if( ultimosPorFecha[key].ultimaModificacion < indicador.ultimaModificacion ) 
                        {
                            ultimosPorFecha[key] = indicador
                        }
                        
                    })

                    // elimina de cada objeto de ultimosPorFecha aquellas propiedades que tienen valor nulo
                    Object.keys(ultimosPorFecha).forEach(key => {
                        Object.keys(ultimosPorFecha[key]).forEach(prop => {
                            if (ultimosPorFecha[key][prop] == null) {
                                delete ultimosPorFecha[key][prop]
                            }
                        })
                    })

                    //Transforma el objeto en un array y lo ordena por la llave del objeto
                    ultimosPorFecha = Object.values(ultimosPorFecha).sort((a, b) => a.pesvIndicadoresTipoId - b.pesvIndicadoresTipoId)

                    this.logs = ultimosPorFecha
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error',
                        text: err.message,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    })
                })


        },
        calc(formula, data) {
            let result = formula.split(" ").map(key => {
                if (key == "*" || key == "/" || key == "+" || key == "-" || key == "100") {
                    return key
                } else {
                    return data[key]
                }
            }).join("")
            return eval(result)
        },
        callBackSubmit(self) {
            let model = {}
            model[self.data.variable] = this.calc(self.data.formula, self.data)
            model = { ...model, ...self.data }
            model['PESVIndicadoresTipoId'] = model.id
            model['SucursalId'] = this.sucursal.value
            delete model.id
            // console.log("model", model)
            self.loader = true
            this.$store.getters.fetchPost({ path: 'PESVIndicadores/agregar', data: model })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al guardar')
                })
                .then(() => {
                    Swal.fire({
                        title: 'Guardado',
                        text: `El indicador ${self.data.variable} con resultado de ${model[self.data.variable]} ha sido guardado`,
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    })

                    self.schema.forEach(item => {
                        item.value = null
                    })
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error',
                        text: err.message,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    })
                })
                .finally(() => {
                    self.loader = false
                })
        },
        sanityData( indicador ) {
            let keys = Object.keys( indicador )
            let variable = indicador.pesvIndicadoresTipo.variable
            let key = keys.find( key => key.toLowerCase() == variable.toLowerCase() )
            return indicador[key]
        }
    }
}
</script>

<template>
    <div class="container-fluid">
        <card class="p-3">
            <h3 class="mb-3">Indicadores PESV</h3>
            <sucursalSelector @getSucursal="setSucursal" />
            <div class="d-flex flex-reverse" style="width: 100%">
                <vs-button v-if="!isInRead" success :disabled="!sucursal" @click="isInRead = 1">Crear
                    indicador</vs-button>
                <vs-button v-else @click="isInRead = 0" success :disabled="!sucursal">Ver
                    indicadores</vs-button>
            </div>
        </card>
        <card class="p-3" v-if="sucursal">
            <b-list-group v-if="isInRead">
                <b-list-group-item v-for="tipo in tiposDeIndicadores" :key="tipo.variable">
                    <h4>{{ tipo.descripcion }}</h4>
                    <BVForm :callBackSubmit="callBackSubmit" :injectData="{ ...tipo }" :schema="$data[tipo.variable]">
                    </BVForm>
                </b-list-group-item>
            </b-list-group>
            <div v-else>
                <div class="indicator-container">
                    <div class="indicator" v-for="(indicator, key) in logs" :key="key">
                        <h2>{{ indicator.pesvIndicadoresTipo.variable }}</h2>
                        <p>{{ indicator.pesvIndicadoresTipo.descripcion }}</p>
                        <div class="values">
                            <div class="value">Valor: {{ sanityData(indicator) }}
                            </div>
                            <div class="value">Fórmula: {{ indicator.pesvIndicadoresTipo.formula }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </card>
    </div>
</template>

<style scoped>
.indicator-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.indicator {
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.indicator h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.indicator p {
  font-size: 1rem;
  color: #555;
}

.values {
  margin-top: 15px;
}

.value {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

</style>